import React, { ChangeEvent, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTIcon } from '../../../helpers'
import Select from 'react-select'
import { setCreditWatchStatusDeclined } from '../../../../app/modules/subscriptions/core/_requests'
import { InvoiceDetailsLoading } from '../../../../app/modules/invoices/invoice-list/components/loading/InvoiceDetailsLoading'
type Props = {
  show: boolean
  handleClose: () => void
  cwData: any
  onDataFromChild: any
  // paymentRequest: PaymentRequest
}

const DeclinedCW: React.FC<Props> = ({ show, handleClose, cwData, onDataFromChild }) => {
  // console.log('here is cwData data', cwData)
  const [declineRemarks, setDeclineRemarks] = useState<string>('')
  const [declineReasons, setDeclineReasons] = useState<any>([])
  const [approvedForFlexirent, setApprovedForFlexirent] = useState<boolean>(false)
  const [customError, setCustomError] = useState<string | undefined>('')
  const [hasLoading, setHasLoading] = useState<boolean>(false)

  const options = [
    { value: 'Driving Record', label: 'Driving Record' },
    { value: 'Criminal Record', label: 'Criminal Record' },
    { value: 'Financial Status', label: 'Financial Status' },
    { value: 'Blacklisted Client', label: 'Blacklisted Client' },
    { value: 'ABN Expired', label: 'ABN Expired' },
  ]
  const dissmissLocation = () => {
    handleClose();
    setApprovedForFlexirent(false)
  }

  const handleSubmit = () => {
    setHasLoading(true)
    if (cwData !== '') {
      if (declineRemarks !== '') {
        let postBody: object = {
          app_id: cwData?.appid,
          org_id: cwData?.orgid,
          hs_contact_id: cwData?.hs_id,
          decline_remarks: declineRemarks,
          decline_reasons: declineReasons,
          ts_status: 'DECLINED',
          deal_id: cwData?.deal_id,
          approved_for_flexirent: approvedForFlexirent,
        }
        setCreditWatchStatusDeclined(postBody).then((response: any) => {
          setHasLoading(false)
          console.log('declined response', response)
          if (response?.success === true) {
            setHasLoading(false)
            onDataFromChild(response)
            dissmissLocation()
            setDeclineRemarks('')
            setDeclineReasons([])
          } else {
            setHasLoading(false)
            onDataFromChild(response)
            dissmissLocation()
            setDeclineRemarks('')
            setDeclineReasons([])
          }
        })
      } else {
        setHasLoading(false)
        setCustomError('Technica issue occured!!')
        dissmissLocation()
        setDeclineRemarks('')
        setDeclineReasons([])
      }
    } else {
      setHasLoading(false)
      setCustomError('Technica issue occured!!')
      dissmissLocation()
      setDeclineRemarks('')
      setDeclineReasons([])
    }
  }

  const handleRemarkChange = (event: any) => {
    setDeclineRemarks(event.target.value)
  }

  const handleDeclineReasonChange = (selectedVals: object) => {
    setDeclineReasons(selectedVals)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setApprovedForFlexirent(event.target.checked)
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-lg modal-dialog-centered max-width-380'
      aria-hidden='true'
      onHide={dissmissLocation}
    >
      <div className='modal-content'>
        {hasLoading && <InvoiceDetailsLoading />}
        <div className='modal-header'>
          <h5 className='modal-title'>Decline Application</h5>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={dissmissLocation}
          >
            <KTIcon iconName='cross' className='fs-2x' />
          </div>
        </div>

        <div className='modal-body'>
          <form className='form w-100' noValidate id='kt_login_signin_form'>
            {/* begin::Heading */}
            {/* <div className=' mb-11'>
              <div className='text-gray-500 fw-semibold fs-6'>Dummy content</div>
            </div> */}
            {/* begin::Heading */}
            {/* begin::Form group */}
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>Denied Reason(s)*</label>
              <Select
                isMulti={true}
                options={options}
                value={declineReasons}
                onChange={handleDeclineReasonChange}
              />
            </div>
            <div className='fv-row mb-8'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='flexirentCheck'
                  checked={approvedForFlexirent}
                  onChange={handleCheckboxChange}
                />
                <label className='form-label fs-6 fw-bolder text-dark' htmlFor='flexirentCheck'>
                  Approved for Flexirent
                </label>
              </div>
            </div>
            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>Remark</label>
              <textarea
                id='item'
                name='item'
                className='form-control bg-white editable-textarea'
                placeholder='Please enter your remark(s)'
                onChange={(e) => handleRemarkChange(e)}
                value={declineRemarks}
              ></textarea>
            </div>
            {/* end::Form group */}
          </form>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-light-primary' onClick={dissmissLocation}>
            Cancel
          </button>
          {hasLoading ? (
            <button id='submit' type='button' className='btn btn-primary'>
              <span className=''>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            </button>
          ) : (
            <button id='submit' type='button' className='btn btn-primary' onClick={handleSubmit}>
              <span className='indicator-label'>Decline</span>
              {/*end::Indicator label*/}
              {/*begin::Indicator progress*/}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export { DeclinedCW }
