import moment from 'moment'

export function DateFormat(date: string) {
  return moment(date).format('dddd, MMMM Do, YYYY h:mm:ss A')
}

export function DateTimeFormat(date: string) {
  return date.split(/[?#]/)[0]
}

export function DateDifference(date1: any, date2: any) {
  const billdate = new Date(moment(new Date()).format('YYYY-MM-DD'))
  const startdate = new Date(moment(date2).format('YYYY-MM-DD'))
  const diff = billdate.getTime() - startdate.getTime()
  const msInDay = 1000 * 60 * 60 * 24
  const days = Math.floor(diff / msInDay)
  let result = ''

  if (diff > 0) {
    result = `Overdue from ${Math.abs(days)} days`
  } else {
    result = `Due in ${Math.abs(days)} days`
  }

  return result
}

export function NumbertoWord(n: any) {
  if (n < 0) return false
  let single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
  let double_digit = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ]
  let below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
  if (n === 0) return 'Zero'
  function translate(n: any) {
    let word = ''
    if (n < 10) {
      word = single_digit[n] + ' '
    } else if (n < 20) {
      word = double_digit[n - 10] + ' '
    } else if (n < 100) {
      let rem = translate(n % 10)
      word = below_hundred[(n - (n % 10)) / 10 - 2] + ' ' + rem
    } else if (n < 1000) {
      word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
    } else if (n < 1000000) {
      word = translate(n / 1000).trim() + ' Thousand ' + translate(n % 1000)
    } else if (n < 1000000000) {
      word = translate(n / 1000000).trim() + ' Million ' + translate(n % 1000000)
    } else {
      word = translate(n / 1000000000).trim() + ' Billion ' + translate(n % 1000000000)
    }
    return word
  }
  let result = translate(n)
  return result.trim()
}

export function AmountFormatter(n?: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return formatter.format(Number(n))
}

export function _addDaysInDate(datetoadd: string = '', daytoadd: any = 0) {
  const date = new Date(datetoadd)

  const numberOfDaysToAdd = parseInt(daytoadd)
  // console.log('add date', datetoadd, date, daytoadd, numberOfDaysToAdd)
  return date.setDate(date.getDate() + numberOfDaysToAdd)
}

export function _dayCounter(datetoadd: string = '', daytoadd: any = 0) {
  const currentDate: any = new Date()
  const toDate: any = new Date(_addDaysInDate(datetoadd, daytoadd))

  const differenceInMilliseconds = toDate - currentDate

  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)

  return differenceInDays
}
